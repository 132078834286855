import * as React from 'react'
import Layout from '@components/Layout'

const Music = () => {
  return (
    <Layout title="Not Found">
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Layout>
  )
}

export default Music
